import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
	vectorText,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable } from 'drizzle-orm/pg-core'
import { projectSchema } from './projectSchema'

const tableName = 'brand_voice'

export const brandVoiceSchema = pgTable(
	tableName,
	(t) => ({
		...standardFields,
		projectId: t.varchar().references(() => projectSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
		vectorText,
	}),
	(table) => tenantIdIndex(tableName, table),
)

export const brandVoiceRelations = relations(brandVoiceSchema, ({ one }) => ({
	ownerId: one(userSchema, {
		fields: [brandVoiceSchema.ownerId],
		references: [userSchema.id],
	}),
	projectId: one(projectSchema, {
		fields: [brandVoiceSchema.projectId],
		references: [projectSchema.id],
	}),
	tenantId: one(tenantSchema, {
		fields: [brandVoiceSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))

import {
	isAccepted,
	isAddedManually,
	isSuggested,
	priority,
	standardFields,
	suggestedDescription,
	tenantIdIndex,
	tenantSchema,
	userSchema,
	vectorText,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable } from 'drizzle-orm/pg-core'
import { featureTemplateSchema } from './featureTemplateSchema'
import { projectSchema } from './projectSchema'

const tableName = 'feature'

export const featureSchema = pgTable(
	tableName,
	(t) => ({
		...standardFields,
		featureTemplateId: t.varchar().references(() => featureTemplateSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
		isAccepted,
		isAddedManually,
		isSuggested,
		priority,
		projectId: t.varchar().references(() => projectSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
		suggestedDescription,
		vectorText,
	}),
	(table) => tenantIdIndex(tableName, table),
)

export const featureRelations = relations(featureSchema, ({ one }) => ({
	featureTemplate: one(featureTemplateSchema, {
		fields: [featureSchema.featureTemplateId],
		references: [featureTemplateSchema.id],
	}),
	ownerId: one(userSchema, {
		fields: [featureSchema.ownerId],
		references: [userSchema.id],
	}),
	projectId: one(projectSchema, {
		fields: [featureSchema.projectId],
		references: [projectSchema.id],
	}),
	tenantId: one(tenantSchema, {
		fields: [featureSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))

import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
	vectorText,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable } from 'drizzle-orm/pg-core'
import { eventSchema } from './eventSchema'
import { featureSchema } from './featureSchema'
import { tagToProject } from './generatedRelations'
import { modelSchema } from './modelSchema'
import { pageSchema } from './pageSchema'
import { painSchema } from './painSchema'
import { personaSchema } from './personaSchema'
import { pitchSchema } from './pitchSchema'
import { userFlowSchema } from './userFlowSchema'

const tableName = 'project'

export const projectSchema = pgTable(
	tableName,
	(t) => ({
		...standardFields,
		stage: t.varchar(),
		standardFeatures: t.jsonb().$type<string[]>(),
		vectorText,
	}),
	(table) => tenantIdIndex(tableName, table),
)

export const projectRelations = relations(projectSchema, ({ many, one }) => ({
	events: many(eventSchema),
	features: many(featureSchema),
	models: many(modelSchema),
	ownerId: one(userSchema, {
		fields: [projectSchema.ownerId],
		references: [userSchema.id],
	}),
	pages: many(pageSchema),
	pains: many(painSchema),
	personas: many(personaSchema),
	pitches: many(pitchSchema),
	tag: many(tagToProject),
	tenantId: one(tenantSchema, {
		fields: [projectSchema.tenantId],
		references: [tenantSchema.id],
	}),
	userFlows: many(userFlowSchema),
}))

import { omit } from '#app/utils/lodash'
import { standardFields, vectorText } from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable, uniqueIndex } from 'drizzle-orm/pg-core'
import { appTemplateToFeatureTemplateSchema } from './appTemplateToFeatureTemplateSchema'

const tableName = 'feature_template'

export const featureTemplateSchema = pgTable(
	tableName,
	{
		...omit(standardFields, ['ownerId', 'tenantId']),
		vectorText,
	},
	(t) => ({
		uniqueIndex: uniqueIndex('featureTemplateNameUniqueIndex').on(t.name),
	}),
)

export const featureTemplateRelations = relations(
	featureTemplateSchema,
	({ many }) => ({
		appTemplate: many(appTemplateToFeatureTemplateSchema),
	}),
)

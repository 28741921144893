import { omit } from '#app/utils/lodash'
import { relations } from 'drizzle-orm'
import { pgTable } from 'drizzle-orm/pg-core'
import {
	isAccepted,
	isAddedManually,
	isSuggested,
	priority,
	standardFields,
	suggestedDescription,
	tenantSchema,
	vectorText,
} from './baseSchema'
import { projectSchema } from './projectSchema'

// Processes table
export const userFlowSchema = pgTable('user_flows', (t) => ({
	...omit(standardFields, ['ownerId']),
	isAccepted,
	isAddedManually,
	isStandard: t.boolean().default(false),
	isSuggested,
	priority,
	projectId: t.varchar().references(() => projectSchema.id, {
		onDelete: 'cascade',
		onUpdate: 'cascade',
	}),
	suggestedDescription,
}))

export const userFlowRelations = relations(userFlowSchema, ({ many, one }) => ({
	projectId: one(projectSchema, {
		fields: [userFlowSchema.projectId],
		references: [projectSchema.id],
	}),
	steps: many(stepSchema),
	tenantId: one(tenantSchema, {
		fields: [userFlowSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))

// Steps table (each UserFlow consists of multiple steps)
export const stepSchema = pgTable('steps', (t) => ({
	...omit(standardFields, ['ownerId']),
	projectId: t.varchar().references(() => projectSchema.id, {
		onDelete: 'cascade',
		onUpdate: 'cascade',
	}),
	stepOrder: t.integer().notNull(),
	userFlowId: t.varchar().references(() => userFlowSchema.id, {
		onDelete: 'cascade',
		onUpdate: 'cascade',
	}),
	vectorText,
}))

export const stepRelations = relations(stepSchema, ({ one }) => ({
	projectId: one(projectSchema, {
		fields: [stepSchema.projectId],
		references: [projectSchema.id],
	}),
	tenantId: one(tenantSchema, {
		fields: [stepSchema.tenantId],
		references: [tenantSchema.id],
	}),
	userFlow: one(userFlowSchema, {
		fields: [stepSchema.userFlowId],
		references: [userFlowSchema.id],
	}),
}))

// Step Conditions table (defines conditions between steps)
// export const stepConditionSchema = pgTable('step_conditions', (t) => ({
// 	...omit(standardFields, ['ownerId']),
// 	stepId: t.varchar().references(() => stepSchema.id),
// 	condition: t.text().notNull(), // Condition for moving to the next step
// 	processId: t.varchar().references(() => userFlowSchema.id, {
// 		onDelete: 'cascade',
// 		onUpdate: 'cascade',
// 	}),
// }))

// Actors table (defines roles or users involved in processes)
// export const actorSchema = pgTable('actors', (t) => ({
// 	...omit(standardFields, ['ownerId']),
// 	// processId: t.uuid("process_id").references(() => processes.id).notNull(), // Foreign key to processes
// 	name: t.text().notNull(), // Actor name or role (e.g., "Manager", "Reviewer")
// 	actorType: t.text().notNull(), // e.g., role, individual user
// }))

// Triggers table (defines events or actions that start or transition a process)
// export const triggerSchema = pgTable('triggers', (t) => ({
// 	...omit(standardFields, ['ownerId']),
// 	processId: t.varchar().references(() => userFlowSchema.id, {
// 		onDelete: 'cascade',
// 		onUpdate: 'cascade',
// 	}),
// 	// processId: t.uuid("process_id").references(() => processes.id).notNull(), // Foreign key to processes
// 	// stepId: t.uuid("step_id").references(() => steps.id).notNull(), // Foreign key to steps
// 	// triggerType: t.text().notNull(), // e.g., "time-based", "action-based"
// 	// triggerCondition: t.text("trigger_condition").notNull(), // e.g., "on approval", "on task completion"
// }))

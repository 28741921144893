import { omit } from '#app/utils/lodash.ts'
import {
	isAccepted,
	isSuggested,
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable, varchar } from 'drizzle-orm/pg-core'
import { projectSchema } from './projectSchema'

const tableName = 'inspiration_website'

export const inspirationWebsiteSchema = pgTable(
	tableName,
	(t) => ({
		...omit(standardFields, ['name']),
		isAccepted,
		isSuggested,
		projectId: t.varchar().references(() => projectSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
		url: varchar('url').notNull(),
	}),
	(table) => tenantIdIndex(tableName, table),
)

export const inspirationWebsiteRelations = relations(
	inspirationWebsiteSchema,
	({ one }) => ({
		ownerId: one(userSchema, {
			fields: [inspirationWebsiteSchema.ownerId],
			references: [userSchema.id],
		}),
		projectId: one(projectSchema, {
			fields: [inspirationWebsiteSchema.projectId],
			references: [projectSchema.id],
		}),
		tenantId: one(tenantSchema, {
			fields: [inspirationWebsiteSchema.tenantId],
			references: [tenantSchema.id],
		}),
	}),
)

import { relations } from 'drizzle-orm'
import { pgTable, primaryKey, varchar } from 'drizzle-orm/pg-core'
import { tenantSchema } from './baseSchema.ts'
import { featureSchema } from './featureSchema.ts'
import { modelSchema } from './modelSchema.ts'
import { personaSchema } from './personaSchema.ts'
import { projectSchema } from './projectSchema.ts'
import { tagSchema } from './tagSchema.ts'
import { userFlowSchema } from './userFlowSchema.ts'

export const tagToFeature = pgTable(
	'tag_to_feature',
	{
		featureId: varchar('feature_id')
			.notNull()
			.references(() => featureSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tagId: varchar('tag_id')
			.notNull()
			.references(() => tagSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.tagId, t.featureId] }),
	}),
)

export const tagToFeatureRelations = relations(tagToFeature, ({ one }) => ({
	feature: one(featureSchema, {
		fields: [tagToFeature.featureId],
		references: [featureSchema.id],
	}),
	tag: one(tagSchema, {
		fields: [tagToFeature.tagId],
		references: [tagSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [tagToFeature.tenantId],
		references: [tenantSchema.id],
	}),
}))

export const tagToProject = pgTable(
	'tag_to_project',
	{
		projectId: varchar('project_id')
			.notNull()
			.references(() => projectSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tagId: varchar('tag_id')
			.notNull()
			.references(() => tagSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.tagId, t.projectId] }),
	}),
)

export const tagToProjectRelations = relations(tagToProject, ({ one }) => ({
	project: one(projectSchema, {
		fields: [tagToProject.projectId],
		references: [projectSchema.id],
	}),
	tag: one(tagSchema, {
		fields: [tagToProject.tagId],
		references: [tagSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [tagToProject.tenantId],
		references: [tenantSchema.id],
	}),
}))

export const tagToPersona = pgTable(
	'tag_to_persona',
	{
		personaId: varchar('persona_id')
			.notNull()
			.references(() => personaSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tagId: varchar('tag_id')
			.notNull()
			.references(() => tagSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.tagId, t.personaId] }),
	}),
)

export const tagToPersonaRelations = relations(tagToPersona, ({ one }) => ({
	persona: one(personaSchema, {
		fields: [tagToPersona.personaId],
		references: [personaSchema.id],
	}),
	tag: one(tagSchema, {
		fields: [tagToPersona.tagId],
		references: [tagSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [tagToPersona.tenantId],
		references: [tenantSchema.id],
	}),
}))

export const tagToUserFlow = pgTable(
	'tag_to_user_flow',
	{
		tagId: varchar('tag_id')
			.notNull()
			.references(() => tagSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		userFlowId: varchar('user_flow_id')
			.notNull()
			.references(() => userFlowSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.tagId, t.userFlowId] }),
	}),
)

export const tagToUserFlowRelations = relations(tagToUserFlow, ({ one }) => ({
	tag: one(tagSchema, {
		fields: [tagToUserFlow.tagId],
		references: [tagSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [tagToUserFlow.tenantId],
		references: [tenantSchema.id],
	}),
	userFlow: one(userFlowSchema, {
		fields: [tagToUserFlow.userFlowId],
		references: [userFlowSchema.id],
	}),
}))

export const tagToModel = pgTable(
	'tag_to_model',
	{
		modelId: varchar('model_id')
			.notNull()
			.references(() => modelSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tagId: varchar('tag_id')
			.notNull()
			.references(() => tagSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			}),
	},
	t => ({
		pk: primaryKey({ columns: [t.tenantId, t.tagId, t.modelId] }),
	}),
)

export const tagToModelRelations = relations(tagToModel, ({ one }) => ({
	model: one(modelSchema, {
		fields: [tagToModel.modelId],
		references: [modelSchema.id],
	}),
	tag: one(tagSchema, {
		fields: [tagToModel.tagId],
		references: [tagSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [tagToModel.tenantId],
		references: [tenantSchema.id],
	}),
}))


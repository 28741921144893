import { relations } from 'drizzle-orm'
import { boolean, pgTable } from 'drizzle-orm/pg-core'
import {
	standardFields,
	tenantIdIndex,
	tenantSchema,
	userSchema,
} from './baseSchema.ts'

export const tagSchema = pgTable(
	'tag',
	{
		...standardFields,
		is_suggested: boolean('is_suggested').default(false),
	},
	(table) => tenantIdIndex('tag', table),
)

export const tagRelations = relations(tagSchema, ({ one }) => ({
	ownerId: one(userSchema, {
		fields: [tagSchema.ownerId],
		references: [userSchema.id],
	}),
	tenantId: one(tenantSchema, {
		fields: [tagSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))
